import BaseService from "./BaseService";

class BlockService extends BaseService {

    async getAll(req) {
        const response = await this.axios.post('/block/all', req);
        return await response.data;
    }

    async getDetails(req) {
        const response = await this.axios.post('/block/details', req);
        return await response.data;
    }

    async deleteBlock(req) {
        const response = await this.axios.post('/block/delete', req);
        return response.data;
    }

    async blockBlock(req) {
        const response = await this.axios.post('/block/block', req);
        return response.data;
    }

    async createBlock(req) {
        const response = await this.axios.post('/block/create', req);
        return response.data;
    }

    async getPaymentProfile(req) {
        const response = await this.axios.post('/block/payment_profile', req);
        return response.data;
    }

    async getBlockPercentages(req) {
        const response = await this.axios.post('/block/percentages', req);
        return response.data;
    }

    async updateBlockPercentages(req) {
        const response = await this.axios.post('/block/percentages/update', req);
        return response.data;
    }

    async getBlockMiscellaneous(req) {
        const response = await this.axios.post('/block/miscellaneous', req);
        return response.data;
    }
    async getBlockLimits(req) {
        const response = await this.axios.post('/block/limits', req);
        return response.data;
    }
    async getQuota(req) {
        const response = await this.axios.post('/block/quota', req);
        return response.data;
    }

    async fecthBlockPsxcmxbanxdis(req) {
        const response = await this.axios.post('/block/fetch_psxcmxbanxdis', req);
        return response.data;
    }

    async getBankings(req) {
        const response = await this.axios.post('/block/bankings', req);
        return response.data;
    }

}

export default BlockService
